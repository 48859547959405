const companyFields = {
  cardSurcharge: 86,
  country: 134,
  automaticCardSurcharge: 140,
  automaticApproveDepositsFromSale: 143,
  useExperimentalBalance: 145,
  seeMeasureUnit: 146,
  useDraftPurchases: 148,
  useDraftExpenses: 149,
  useExportationInvoice: 151,
  spreadsheetId: 158,
  salesSheetId: 159,
  itemsSheetId: 160,
  cxcSheetId: 161,
  pendingDispatchSpreadsheetId: 170,
  pendingDispatchSheetId: 171,
  kardexSpreadsheetId: 172,
  kardexSheetId: 173,
  cxcSpreadsheetId: 175,
  cxcDetailSheetId: 176,
}

export const companyFieldsPurchase = {
  addTaxDetailed: 168,
}

export const companyFieldsFel = {
  useFEL: 90,
}

export const companyFieldsQuote = {
  quoteContactByUser: 164,
  quoteNameFieldReference: 165,
  quoteNameFieldRepresentative: 166,
}

export const companyFieldsSell = {
  canChangeInventoryReturnOptionByCompany: 142,
  useDraftSale: 144,
  enabledZeroPrices: 147,
  experimentalConfirmSale: 152,
}

export const companyFieldsInvoice = {
  automaticFEL: 117,
  invoiceCredit: 154,
  footerInvoiceCredit: 155,
  taxDetailed: 156,
  taxIncluded: 157,
  showUserCreator: 162,
  sellWithSFCCorrelative: 167,
  qrInvoice: 169,
  enableViewSellPaymentsIntoBillTicket: 174,
}

export const companyFieldsTurns = {
  transactionsWithActiveTurn: 177,
  seeUtilityInTurns: 210,
}

export const companyFieldsInventory = {
  batchInDocuments: 178,
  expirationDateInDocuments: 179,
}

export const companyFieldsTicket = {
  emisorNameInTicket: 180,
  emailInTicket: 181,
  phoneInTicket: 182,
  sellNumberInTicket: 183,
  descriptionInTicket: 184,
  totalInLettersInTicket: 185,
  oneTotalInTicket: 186,
  nitInTicket: 187,
  authFieldInTicket: 188,
  serieAndAuthNumberInTicket: 189,
  nrcInTicket: 190,
  economicActivityInTicket: 191,
  establishmentTypeInTicket: 192,
  taxAndExemptInTicket: 193,
  taxReceiptInTicket: 194,
  generationNumberInTicket: 195,
  receiptSealInTicket: 196,
  controlNumberInTicket: 197,
  invoicingModelInTicket: 198,
  clientNitAndNrcInTicket: 199,
  clientEconomicActivityInTicket: 200,
  emitterInTicket: 201,
  receiverInTicket: 202,
  exemptAcquirerDataInTicket: 203,
  exemptPurchaseCorrelativeInTicket: 204,
  sagInTicket: 205,
  caiInTicket: 206,
  limitEmissionDateInTicket: 207,
  authorizedRankInTicket: 208,
  exemptCorrelativeInTicket: 209,
}

export default companyFields
