import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Td, Tr } from 'react-super-responsive-table'
import Alert from 'sweetalert-react'
import { Col, Modal, Row } from 'react-bootstrap'
import {
  Action,
  Button,
  Card,
  Dropdown as DropdownCustom,
  ModalTable,
  Select as CustomSelect,
  Select,
  Switch,
  TableV2,
  Title,
  SelectedDates,
  Icon as IconButton,
  FAB,
  Commentary,
  Icon,
  FormText,
} from 'src/components'
import Folder, { categoryType } from 'src/components/folders/Folder'
import {
  faArchive,
  faEllipsisV,
  faFileExcel,
  faMapMarker,
  faSdCard,
  faSlidersH,
  faTimes,
  faUserAlt,
  faInfoCircle,
  faTicketAlt,
  faSave,
} from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  changeKoloState,
  getAllWarehouses,
  printQR,
  setAttendantsWarehouses,
  activeSingleWarehouse,
  deleteByList,
  saveWarehouse,
  exportWarehouseKardex,
  updateWialonWarehouse,
  getExcelByWarehouse,
  getDetailedExcelByWarehouse,
  syncKardexSpreadsheet,
} from 'src/actions/warehouse.actions'

import {
  selectAllWarehouses,
  selectTotalWarehouses,
} from 'src/selectors/warehouse.selector'

import { getUsersChildrenByModule } from 'src/actions/modules.actions'
import {
  isAllowed,
  selectCurrentUserRoles,
  selectUsers,
} from 'src/selectors/modules.selector'

import { selectCurrentUser } from 'src/selectors/user.selector'

import {
  actionTypes as action,
  assignCategorizationToEntity,
  getAllCategorizations,
  getCategorizationsByEntity,
} from 'src/actions/categorization.actions'
import { selectAllCategorizations } from 'src/selectors/categorizations.selector'

import {
  types,
  changeArchivedStatus,
  changeArchivedStatusByList,
  getArchivedData,
} from 'src/actions/utilities.actions'
import { selectArchivedData } from 'src/selectors/utilities.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  hasErrorsSelector,
  singleErrorSelector,
  handlerError,
  handlerSuccess,
  handlerInfoWithButtons,
  hasErrors,
} from 'src/selectors/error.selector'

import { WarehouseLocation } from './WarehouseLocation/WarehouseLocation'

import InventoryHistory from 'src/components/inventory/InventoryHistory'
import WarehouseComponent from './WarehouseComponent'
import WarehouseInventory from './WarehouseInventory'
import WarehouseItemsMinMax from './WarehouseMinMax/WarehouseItemsMinMax'
import { selectSetCurrentCompany } from 'src/selectors/company.selector'
import { inventoriesPermissions } from 'src/enums/permissions'
import { getCSVInventoryProducts } from 'src/actions/inventory.actions'
import { showAlert } from 'src/actions/alert.actions'

const defaultValue = { value: null, label: '- Todos -' }

const statusOptions = [
  defaultValue,
  { value: 1, label: 'Activo' },
  { value: 2, label: 'Inactivo' },
]

const warehousesOptions = [
  defaultValue,
  { value: 1, label: 'Ver mis bodegas' },
  { value: 2, label: 'Ver bodegas de otros' },
]

/**
 * Pantalla de Bodegas
 * @component
 */
const WarehouseV2 = () => {
  const dispatch = useDispatch()

  const user = useSelector(selectCurrentUser)
  const company = useSelector(selectSetCurrentCompany)
  const roles = useSelector(selectCurrentUserRoles)
  const users = useSelector(selectUsers)
  const archived = useSelector(selectArchivedData)
  const companyWarehouse = company?.warehouse

  const warehouses = useSelector(selectAllWarehouses).map(warehouse =>
    warehouse.id === companyWarehouse ? { ...warehouse, isPrincipal: true } : warehouse,
  )
  const total = useSelector(selectTotalWarehouses)
  const loading = useSelector(state =>
    loadingSelector([actionTypes.GET_ALL_WAREHOUSES2])(state),
  )
  const loadingDelete = useSelector(state =>
    loadingSelector([actionTypes.DELETE_WAREHOUSE])(state),
  )

  const loadingKardex = useSelector(state =>
    loadingSelector([actionTypes.DOWNLOAD_GENERAL_KARDEX])(state),
  )

  const categorizations = useSelector(selectAllCategorizations)
  const loadingAssigned = useSelector(state =>
    loadingSelector([action.GET_ASSIGNED])(state),
  )
  const loadingAddCategorization = useSelector(state =>
    loadingSelector([action.ADD_ENTITY_CATEGORIZATION])(state),
  )
  const hasErrorAddCategorization = useSelector(state =>
    hasErrorsSelector([action.ADD_ENTITY_CATEGORIZATION])(state),
  )
  const errorAddCategorization = useSelector(state =>
    singleErrorSelector([action.ADD_ENTITY_CATEGORIZATION])(state),
  )

  const loadingUpdateAttendants = useSelector(state =>
    loadingSelector([actionTypes.UPDATE_ATTENDANTS])(state),
  )
  const hasErrorUpdateAttendants = useSelector(state =>
    hasErrorsSelector([actionTypes.UPDATE_ATTENDANTS])(state),
  )
  const errorUpdateAttendants = useSelector(state =>
    singleErrorSelector([actionTypes.UPDATE_ATTENDANTS])(state),
  )

  const loadingStatus = useSelector(state =>
    loadingSelector([types.CHANGE_STATUS_DATA])(state),
  )
  const errorStatus = useSelector(state => hasErrors([types.CHANGE_STATUS_DATA])(state))

  const loadingChange = useSelector(state =>
    loadingSelector([actionTypes.ACTIVE_WAREHOUSE, actionTypes.CHANGE_KOLO_STATE])(state),
  )
  const hasErrorChange = useSelector(state =>
    hasErrorsSelector([actionTypes.ACTIVE_WAREHOUSE, actionTypes.CHANGE_KOLO_STATE])(
      state,
    ),
  )
  const errorChange = useSelector(state =>
    singleErrorSelector([actionTypes.ACTIVE_WAREHOUSE, actionTypes.CHANGE_KOLO_STATE])(
      state,
    ),
  )
  const loadingUpdateWialon = useSelector(state =>
    loadingSelector([actionTypes.UPDATE_WIALON_WAREHOUSE])(state),
  )
  const hasErrorUpdateWialon = useSelector(state =>
    hasErrorsSelector([actionTypes.UPDATE_WIALON_WAREHOUSE])(state),
  )
  const errorUpdateWialon = useSelector(state =>
    singleErrorSelector([actionTypes.UPDATE_WIALON_WAREHOUSE])(state),
  )

  const loadingWialonWarehouse = useSelector(state =>
    loadingSelector([actionTypes.GET_GPS_WAREHOUSE])(state),
  )

  const loadingReport = useSelector(state =>
    loadingSelector([
      actionTypes.GET_EXCEL_BY_WAREHOUSE,
      actionTypes.GET_DETAILED_EXCEL_BY_WAREHOUSE,
    ])(state),
  )

  const [modalWialon, setModalWialon] = useState({
    show: false,
    warehouse: null,
    value: null,
  })

  const canAdmin = useSelector(state => isAllowed(state, [1001, 2001, 1158]))
  const canArchive = useSelector(state => isAllowed(state, [1162, 2111]))
  const canCreateWarehouse = useSelector(state => isAllowed(state, [1152, 2102]))
  const canEditWarehouse = useSelector(state => isAllowed(state, [1153, 2103]))
  const canDownloadReport = useSelector(state =>
    isAllowed(state, [inventoriesPermissions.downloadReport]),
  )

  const [actions, setActions] = useState({
    add: false,
    att: false,
    archived: false,
    setWialon: false,
  })
  const [filters, setFilters] = useState({
    active: defaultValue,
    koloDelivery: defaultValue,
    koloStatus: defaultValue,
    search: '',
    skip: 0,
    size: 10,
    view: defaultValue,
  })
  const [selectedWarehouses, setSelectedWarehouses] = useState([])
  const [archivedWarehouses, setArchivedWarehouses] = useState([])
  const [showCategorization, setShowCategorization] = useState({})
  const [history, setHistory] = useState({})
  const [showDetail, setShowDetail] = useState({})
  const [alert, setAlert] = useState({})
  const [location, setLocation] = useState({
    showLocations: false,
    warehousesToLocate: [],
  })
  const [assign, setAssign] = useState({ show: false, attendants: [] })

  const [showArchived, setShowArchived] = useState(false)
  const [inventory, setInventory] = useState({ show: false, id: 0 })
  const [config, setConfig] = useState({ show: false, item: {} })
  const [modalKardexDownload, setKardex] = useState({})

  const [seeMinMax, setSeeMinMax] = useState({ show: false })

  const [flagDelete, setFlagDelete] = useState(false)

  useEffect(() => {
    getArchivedWarehouses()
    dispatch(getUsersChildrenByModule(1000, true))
    dispatch(getAllCategorizations(12))
  }, [])

  useEffect(() => {
    if (loadingDelete) setFlagDelete(true)
    else if (flagDelete) {
      setFlagDelete(false)
      dispatch(showAlert(handlerSuccess()))
      setSelectedWarehouses([])
      setArchivedWarehouses([])

      getArchivedWarehouses()
      getWarehouses()
      setShowArchived(false)
    }
  }, [loadingDelete])

  useEffect(() => {
    if (loadingStatus) setActions({ ...actions, archived: true })
    else if (actions.archived) {
      setActions({ ...actions, archived: false })

      const alert = errorStatus
        ? handlerError(errorStatus.message)
        : {
            ...handlerSuccess(),
            onConfirm: () => {
              setSelectedWarehouses([])
              setArchivedWarehouses([])
              setShowArchived(false)
              getArchivedWarehouses()
              getWarehouses()
            },
          }
      dispatch(showAlert(alert))
    }
  }, [loadingStatus])

  useEffect(() => {
    if (loadingChange) setActions({ ...actions, change: true })
    else if (actions.change) {
      setActions({ ...actions, change: false })
      let alert = {}
      if (hasErrorChange) {
        alert = handlerError(errorChange.message || 'Ha ocurrido un error inesperado.')
        alert.onConfirm = () => setAlert({ ...alert, show: false })
        setAlert({ ...alert })
      } else getWarehouses()
    }
  }, [loadingChange])

  useEffect(() => {
    if (loadingAddCategorization) setActions({ ...actions, add: true })
    else if (actions.add) {
      setActions({ ...actions, add: false })
      let alert = {}
      if (hasErrorAddCategorization) {
        alert = handlerError(
          errorAddCategorization.message || 'Ha ocurrido un error inesperado.',
        )
        alert.onConfirm = () => setAlert({ ...alert, show: false })
      } else
        dispatch(getCategorizationsByEntity('warehouses', showCategorization.selectedId))
      setAlert({ ...alert })
    }
  }, [loadingAddCategorization])

  useEffect(() => {
    if (loadingUpdateWialon) setActions({ ...actions, setWialon: true })
    else if (actions.setWialon) {
      setActions({ ...actions, setWialon: false })
      const alert = {}
      if (hasErrorUpdateWialon) {
        setAlert({
          ...handlerError(
            errorUpdateWialon.message || 'Ha ocurrido un error inesperado.',
          ),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      } else {
        setAlert({
          ...handlerSuccess('Se actualizo el id de wialon correctamente'),
          onConfirm: () => {
            setAlert({ show: false })
            setModalWialon({ show: false, warehouse: null, value: null })
            getWarehouses()
          },
        })
      }
    }
  }, [loadingUpdateWialon])

  useEffect(() => {
    if (loadingUpdateAttendants) setActions({ ...actions, att: true })
    else if (actions.att) {
      setActions({ ...actions, att: false })
      let alert = {}
      if (hasErrorUpdateAttendants) {
        alert = handlerError(
          errorUpdateAttendants.message || 'Ha ocurrido un error inesperado.',
        )
        alert.onConfirm = () => setAlert({ ...alert, show: false })
      }
      setAlert({ ...alert })
    }
  }, [loadingUpdateAttendants])

  useEffect(() => {
    if (!roles || roles.length <= 0) return
    getWarehouses()
  }, [roles, filters])

  const getWarehouses = () => {
    dispatch(
      getAllWarehouses({
        activeId: filters.active.value,
        koloDelivery: filters.koloDelivery.value,
        koloStatus: filters.koloStatus.value,
        search: filters.search,
        start: filters.skip,
        end: filters.size,
        userId:
          filters.view.value || !canAdmin
            ? filters.view.value === 2
              ? -user.id
              : user.id
            : null,
      }),
    )
  }

  const getArchivedWarehouses = () => dispatch(getArchivedData('warehouses'))

  const isSelectedWarehouse = item => selectedWarehouses.some(o => o.id === item.id)
  const isSelectedWarehouseArchived = item =>
    archivedWarehouses.some(o => o.id === item.id)

  const onSelectItem = (e, item) => {
    if (e.target.type || e.target.href) {
      return
    }
    const index = archivedWarehouses.findIndex(i => i.id === item.id)
    if (index === -1) archivedWarehouses.push(item)
    else archivedWarehouses.splice(index, 1)
    setArchivedWarehouses([...archivedWarehouses])
  }

  const onSelectRow = (e, item) => {
    if (e?.target.type || e?.target.href) {
      return
    }
    if (!item) {
      setSelectedWarehouses([])
      return
    }

    const index = selectedWarehouses.findIndex(i => i.id === item.id)
    if (index === -1) selectedWarehouses.push(item)
    else selectedWarehouses.splice(index, 1)
    setSelectedWarehouses([...selectedWarehouses])
  }

  const TooltipComponent = item => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: item.attendantsInfo.map(p => `${p.name}<br/>`).join(''),
        }}
      />
    )
  }

  const buttonElement = (
    <Row className={'button-container'}>
      {canArchive && (
        <IconButton
          tooltip={'Mostrar bodegas archivadas'}
          icon={faArchive}
          onClick={() => setShowArchived(true)}
        />
      )}
    </Row>
  )

  const filterElement = (
    <div>
      <Row>
        <Col className={'borderRight'}>
          <Row>
            <Col xl={4} md={6} sm={6} xs={12}>
              <Select
                label={'Por estado'}
                value={filters.active}
                info={'Filtra las bodegas por el estado'}
                options={statusOptions}
                onChange={value => setFilters({ ...filters, active: value })}
              />
            </Col>
            {canAdmin && (
              <Col xl={4} md={6} sm={6} xs={12}>
                <Select
                  label={'Por visualización'}
                  value={filters.view}
                  info={'Filtra las bodegas por visualización'}
                  options={warehousesOptions}
                  onChange={value => setFilters({ ...filters, view: value })}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  )

  const getActions = item => {
    return (
      <DropdownCustom
        loading={loadingReport || loadingStatus}
        items={[
          {
            title: 'Detalle completo',
            action: () => {
              setShowDetail({ show: true, id: item.id })
            },
          },
          {
            title: 'Ver ítems',
            action: () => {
              setInventory({ show: true, id: item.id, name: item.name })
            },
          },
          {
            title: 'Ver categorías',
            action: () => {
              dispatch(getCategorizationsByEntity('warehouses', item.id))
              setShowCategorization({ show: true, selectedId: item.id })
            },
          },
          {
            show: canArchive && item.id !== companyWarehouse,
            title: 'Archivar bodega',
            action: () => dispatch(changeArchivedStatus('warehouses', item.id)),
          },
          {
            title: 'Ver histórico de transacciones',
            action: () => {
              setHistory({ warehouse: item.id, product: null })
            },
          },
          { title: 'Descargar QR', action: () => dispatch(printQR(item.id)) },
          {
            title: 'Descargar Kardex',
            action: () => {
              setKardex({ ...item, show: true })
            },
          },
          {
            show: item.kolo,
            title: 'Sincronizar inventario con terceros',
            action: () => {
              dispatch(syncKardexSpreadsheet(item.id))
            },
          },
          {
            show: canDownloadReport,
            title: 'Descargar inventario',
            action: () => {
              dispatch(getExcelByWarehouse(item.id))
            },
          },
          {
            show: canDownloadReport,
            title: 'Descargar inventario desglosado',
            action: () => {
              dispatch(getDetailedExcelByWarehouse(item.id))
            },
          },
          {
            title: 'Wialon ID',
            show: true,
            action: () => {
              //dispatch(getGPSWarehouse(item.id))
              setModalWialon({
                show: true,
                warehouse: item,
                value: item.gpsDevice !== null ? item.gpsDevice.wialonId : null,
              })
            },
          },
          {
            title: 'Mínimos y máximos',
            show: true,
            action: () =>
              setSeeMinMax({ show: true, warehouseId: item.id, name: item.name }),
          },
        ]}
      />
    )
  }

  const headers = [
    {
      label: 'Código',
      show: true,
      value: ['code'],
      type: 'text',
      className: 'mini',
    },
    {
      label: 'Nombre',
      show: true,
      value: ['name'],
      type: 'text',
      className: 'medium',
      custom: item => (
        <div>
          <div>{item.name}</div>
          {item.isPrincipal && <Commentary value={'Principal'} edit={false} />}
        </div>
      ),
    },
    {
      label: 'Dirección',
      show: true,
      value: ['address'],
      type: 'text',
      className: 'medium',
    },
    {
      label: 'Punto de venta',
      show: true,
      value: ['pos', 'name'],
      type: 'text',
      className: 'medium',
    },
    {
      label: 'Encargado',
      show: true,
      value: ['attendantData', 'name'],
      type: 'text',
      className: 'medium center',
      custom: item =>
        item.attendantsInfo && item.attendantsInfo.length !== 0 ? (
          item.attendantsInfo.length > 1 ? (
            <label
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '0px',
              }}>
              {' '}
              Varios encargados
              <IconButton
                style={{ alignItems: 'center', display: 'flex', marginLeft: '4px' }}
                tooltip={TooltipComponent(item)}
                icon={faInfoCircle}
                size={'0.25x'}
              />{' '}
            </label>
          ) : (
            item.attendantsInfo[0].name
          )
        ) : item.attendantData ? (
          item.attendantData.name
        ) : (
          'Sin encargado'
        ),
    },
    {
      label: 'Vigencia',
      show: false,
      value: ['log', 'validated'],
      type: 'boolean',
      yes: 'Vigente',
      no: 'No vigente',
    },
    {
      label: 'Almacén KOLo',
      show: false,
      value: ['koloStatus'],
      type: 'boolean',
      yes: 'Disponible',
      no: 'Ocupada',
    },
    {
      label: 'Valor compra',
      show: /*seeSum || seeMoneyBalances*/ false,
      value: ['purchaseReport'],
      type: 'currency',
    },
    {
      label: 'Valor venta',
      show: false,
      value: ['log', 'inventoryReport'],
      type: 'currency',
    },
    {
      label: 'Configuración',
      show: window.innerWidth > 480,
      value: ['config'],
      type: 'text',
      className: 'mini center',
      custom: item => (
        <IconButton
          icon={faSlidersH}
          size={'2x'}
          onClick={() => {
            setConfig({ show: true, item })
          }}
        />
      ),
    },
    {
      label: 'Acciones',
      className: 'mini center',
      show: true,
      config: true,
      custom: item => getActions(item),
    },
  ]

  const renderConfig = (title, component, tooltip) => {
    return (
      <Row style={{ marginBottom: 8, borderBottom: '1px dotted lightgrey' }}>
        <Col className={'text-center'} xs={5} md={5} xl={5}>
          <h5>{title}</h5>
        </Col>
        <Col
          className={'text-center'}
          style={{ alignSelf: 'center', padding: 0 }}
          xs={6}
          md={6}
          xl={5}>
          {component}
        </Col>
        <Col xs={1} md={1} xl={2} style={{ alignSelf: 'center', padding: 0 }}>
          <IconButton
            tooltip={tooltip}
            icon={faInfoCircle}
            color={'lightblue'}
            size={'2x'}
            placement={window.screen.width > 480 ? 'right' : false}
          />
        </Col>
      </Row>
    )
  }

  const handleRecoverWarehouses = ids => {
    dispatch(changeArchivedStatusByList('warehouses', ids))
  }

  const handleDeleteWarehouse = ids => {
    dispatch(
      showAlert({
        ...handlerInfoWithButtons('Advertencia', '¿Desea eliminar esta bodega?'),
        onConfirm: () => {
          dispatch(deleteByList(ids))
          setArchivedWarehouses([])
        },
      }),
    )
  }

  return (
    <div>
      <Title
        title={'Bodegas'}
        // to={'crear'}
        onClick={() => setShowDetail({ show: true, id: 0 })}
        actionTitle={'Crear nueva bodega'}
        action={canCreateWarehouse}
      />
      <Row>
        <Col>
          <Card title={'Listado de Bodegas'} button={buttonElement} white>
            <Action
              onClick={() => setShowDetail({ show: true, id: 0 })}
              actionTitle={'Crear nueva bodega'}
              action={canCreateWarehouse}
            />
            <TableV2
              customClass={'scrollX'}
              loading={loading}
              headers={headers}
              items={warehouses}
              total={total}
              mobileAuto
              placeholder={'Buscar por nombre / código'}
              noItemsLegend={`No hay bodegas con los filtros aplicados`}
              handleChange={search =>
                setTimeout(() => {
                  setFilters({ ...filters, search })
                }, 1000)
              }
              customFilter={filterElement}
              onClickTr={(e, item) => onSelectRow(e, item)}
              getItems={item => onSelectRow(null, item)}
              storageKey={`war-${user.id}`}
              getPagination={(skip, size, search) =>
                setFilters({ ...filters, skip, size, search })
              }
              isSelected={item => isSelectedWarehouse(item)}
            />
          </Card>
        </Col>
      </Row>

      <InventoryHistory
        warehouse={history.warehouse}
        product={history.product}
        productName={history.productName}
        close={() => setHistory({ warehouse: null, product: null, productName: null })}
      />

      <WarehouseComponent
        modal
        show={showDetail.show}
        onHide={isUpdate => {
          setShowDetail({ show: false, id: 0 })
          if (isUpdate) getWarehouses()
        }}
        id={showDetail.id}
      />

      <WarehouseInventory
        id={inventory.id}
        modal
        show={inventory.show}
        name={inventory.name}
        onHide={() => {
          dispatch(saveWarehouse({}))
          setInventory({ id: 0, show: false })
        }}
      />

      <WarehouseLocation
        show={location.showLocations}
        onHide={() => setLocation({ showLocations: false, warehousesToLocate: [] })}
        warehouses={location.warehousesToLocate}
      />

      <Modal
        show={assign.show}
        centered
        aria-labelledby={'contained-modal-title-vcenter'}
        onHide={() => setAssign({ show: false, attendants: [] })}>
        <Modal.Header closeButton>
          <Modal.Title>Asignar Encargado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomSelect
            label={'Encargados de bodega'}
            options={users.filter(s => {
              return assign.attendants.filter(f => f.id === s.id).length === 0
            })}
            onChange={user => {
              assign.attendants.push(user)
              setAssign({ ...assign, attendants: [...assign.attendants] })
            }}
            required
          />
          <Row className={'pl-1'}>
            <br />
            {assign.attendants?.map((p, i) => (
              <div className={'user-tag  ml-2 mt-3'} key={i}>
                <label className={'label-user-tag'}>{p.name ? p.name : p.label}</label>
                <IconButton
                  className={'delete-user-tag'}
                  icon={faTimes}
                  tooltip={'Quitar'}
                  color={'white'}
                  onClick={() =>
                    setAssign({
                      ...assign,
                      attendants: assign.attendants.filter(u => p.id !== u.id),
                    })
                  }
                />
              </div>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            loading={loadingUpdateAttendants}
            onClick={() => {
              if (assign.attendants.length > 0) {
                const warehouses = []
                const attendants = []
                selectedWarehouses.forEach(w => {
                  warehouses.push(w.id)
                })
                assign.attendants.forEach(a => {
                  attendants.push(a.id)
                })
                const requests = { warehouses, attendants }
                dispatch(setAttendantsWarehouses(requests))
                return
              }
              setAssign({ ...assign, error: 'Campo obligatorio' })
            }}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={config.show}
        centered
        aria-labelledby={'contained-modal-title-vcenter'}
        onHide={() => setConfig({ show: false, item: {} })}>
        <Modal.Header closeButton>
          <Modal.Title>Configuración de la bodega</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderConfig(
            'Estado',
            <Switch
              id={config.item.id}
              checked={config.item.active}
              onChange={() => {
                setConfig({
                  ...config,
                  item: { ...config.item, active: !config.item.active },
                })
                dispatch(activeSingleWarehouse(config.item.id))
              }}
            />,
            'Al activar está opción la plataforma permitirá realizar transacciones con esta bodega',
          )}
          {renderConfig(
            'Sincronización con terceros',
            <Switch
              id={config.item.id}
              checked={config.item.kolo}
              onChange={() => {
                setConfig({
                  ...config,
                  item: { ...config.item, kolo: !config.item.kolo },
                })
                dispatch(changeKoloState(config.item.id))
              }}
            />,
            'Al activar está opción de la bodega, el inventario de la bodega se sincronizarán con plataformas de terceros configuradas.',
          )}
        </Modal.Body>
      </Modal>

      <ModalTable
        show={showArchived}
        title={'Bodegas Archivadas'}
        size={'lg'}
        onHide={() => setShowArchived(false)}
        headers={['Nombre', 'Encargado', 'Dirección', '']}
        items={archived}
        renderRow={(item, index) => (
          <Tr
            className={isSelectedWarehouseArchived(item) ? 'active data' : 'data'}
            key={`${index}`}
            onClick={e => onSelectItem(e, item)}>
            <Td>{item.name}</Td>
            <Td>{item.attendantData ? item.attendantData.name : 'Sin encargado'}</Td>
            <Td>{item.address}</Td>
            <Td>
              <DropdownCustom
                loading={loadingDelete || loadingStatus}
                items={[
                  {
                    title: 'Recuperar',
                    action: () => handleRecoverWarehouses([item.id]),
                  },
                  {
                    title: 'Eliminar',
                    action: () => handleDeleteWarehouse([item.id]),
                  },
                ]}
              />
            </Td>
          </Tr>
        )}
        button={archivedWarehouses.length > 0 ? 'Recuperar' : false}
        del={archivedWarehouses.length > 0}
        loadingDel={loadingDelete}
        onDelete={() => handleDeleteWarehouse(archivedWarehouses.map(w => w.id))}
        onClick={() => handleRecoverWarehouses(archivedWarehouses.map(o => o.id))}
      />

      <Folder
        onHide={() => setShowCategorization({ show: false })}
        onAssign={item =>
          dispatch(
            assignCategorizationToEntity(
              'warehouses',
              item.id,
              showCategorization.selectedId,
              true,
            ),
          )
        }
        data1={categorizations?.children ? categorizations.children[0] : {}}
        data2={categorizations?.children ? categorizations.children[1] : {}}
        show={showCategorization.show}
        loading={loadingAssigned}
        type={categoryType.RESTAURANT}
      />

      <FAB
        show
        items={[
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: 'CSV para subida de inventario',
            icon: faFileExcel,
            action: () => {
              dispatch(getCSVInventoryProducts())
            },
            show: true,
          },
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: 'Archivar Bodegas',
            icon: faSdCard,
            action: () =>
              dispatch(
                changeArchivedStatusByList(
                  'warehouses',
                  selectedWarehouses.map(o => o.id),
                ),
              ),
            show:
              canArchive &&
              selectedWarehouses.every(o => o.id !== companyWarehouse) &&
              selectedWarehouses.length > 0,
          },
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: 'Ver Ubicaciones',
            icon: faMapMarker,
            action: () =>
              setLocation({
                showLocations: true,
                warehousesToLocate: selectedWarehouses,
              }),
            show: selectedWarehouses.length > 0,
          },
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: 'Asignar Encargado',
            icon: faUserAlt,
            action: () => setAssign({ show: true, attendants: [] }),
            show: canEditWarehouse && selectedWarehouses.length > 0,
          },
        ]}
        icon={faEllipsisV}
      />

      <Modal
        show={modalKardexDownload.show}
        size={'sm'}
        centered
        onHide={() => setKardex({})}>
        <Modal.Header closeButton>
          <Modal.Title>Descargar Kardex: {modalKardexDownload.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <SelectedDates
              dates={{
                dateFrom: modalKardexDownload.start,
                dateTo: modalKardexDownload.end,
              }}
              onDateChange={(start, end) =>
                setKardex({ ...modalKardexDownload, start, end })
              }
              customSize={{ xl: 12 }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              loading={loadingKardex}
              disabled={!modalKardexDownload.start || !modalKardexDownload.end}
              icon={faFileExcel}
              onClick={() => {
                dispatch(
                  exportWarehouseKardex(
                    {
                      warehouseIds: modalKardexDownload.id,
                      start: modalKardexDownload.start,
                      end: modalKardexDownload.end,
                    },
                    modalKardexDownload.name,
                  ),
                )
              }}>
              Descargar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalWialon.show}
        size={'sm'}
        centered
        onHide={() => setModalWialon({ ...modalWialon, show: false, warehouse: null })}>
        <Modal.Header closeButton>
          <Modal.Title>{modalWialon.warehouse?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <FormText
              loading={loadingWialonWarehouse}
              disabled={loadingWialonWarehouse}
              prepend={<Icon icon={faTicketAlt} tooltip={'Id Wialon'} />}
              label={'Wialon Id'}
              type={'text'}
              value={modalWialon.value}
              onChange={({ target }) => {
                setModalWialon({ ...modalWialon, value: target.value, error: null })
              }}
              error={modalWialon.error}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              disabled={modalWialon.value === modalWialon.warehouse?.gpsDevice?.wialonId}
              icon={faSave}
              loading={loadingWialonWarehouse}
              onClick={() => {
                let flag = true
                if (
                  modalWialon.value !== '' &&
                  !Number.isInteger(Number(modalWialon.value))
                ) {
                  setModalWialon({
                    ...modalWialon,
                    error: 'El id solo debe contener números',
                  })
                  flag = false
                }
                if (flag)
                  dispatch(
                    updateWialonWarehouse(
                      modalWialon.warehouse.id,
                      modalWialon.value === '' ? null : modalWialon.value,
                    ),
                  )
              }}>
              Guardar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <WarehouseItemsMinMax
        show={seeMinMax.show}
        warehouseId={seeMinMax.warehouseId}
        name={seeMinMax.name}
        onHide={() => setSeeMinMax({ show: false })}
      />

      <Alert {...alert} />
    </div>
  )
}

export default WarehouseV2
